import { template as template_90a2c151dc0e4ae391a163185f14c043 } from "@ember/template-compiler";
const FKText = template_90a2c151dc0e4ae391a163185f14c043(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
