import { template as template_5fcd5a2cd2f848b7b4199c88becad8f0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5fcd5a2cd2f848b7b4199c88becad8f0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
