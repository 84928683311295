import { template as template_736b28602a3e40348da6845d09eac8fe } from "@ember/template-compiler";
const FKLabel = template_736b28602a3e40348da6845d09eac8fe(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
